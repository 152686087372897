import React from 'react';
// import logo from './logo.svg';
import token from './tokensolo.png'
import breath from './breathbehavior.png'

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logos-container">
          <img src={breath} className="Upper-logo" alt="upper-logo" />
          <img src={token} className="Lower-logo" alt="lower-logo" />
        </div>
        <p>
          COMING SOON...
        </p>
      </header>
    </div>
  );
}

export default App;
